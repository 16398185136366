<template>
  <div>
    <vx-card actionable class="cardx" title="Admin SPOC Dashboard">
      <vs-row type="flex">
        <!--Team -->
        <vs-col v-if="!getTeam('CM')" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" class="no-padding">
          <vs-select label="Team" class="selectExample" v-model="selected_team">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in teams" />
          </vs-select>
        </vs-col>
        <!-- <vs-col v-else vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" class="no-padding">
          <vs-select disabled label="Team" class="selectExample" v-model="selected_team">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in teams" />
          </vs-select>
        </vs-col> -->
        <!--City -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" v-if="selected_team == 'CM'" class="no-padding">
          <vs-select class="selectExample" label="City" v-model="selected_city">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in cities" />
          </vs-select>
        </vs-col>
        <!--City classification -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" class="no-padding"
          v-if="selected_team == 'CM' && selected_city != ''">
          <vs-select class="selectExample" label="City Classification" v-model="selected_city_classification">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in city_classification" />
          </vs-select>
        </vs-col>
        <!--Course -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" v-if="selected_team == 'ExEd'" class="no-padding" >
          <vs-select class="selectExample" label="Courses" v-model="selected_course">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in courses" />
          </vs-select>
        </vs-col>
        <!--Spocs -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" v-if="
          selected_team != 'CM' &&
          selected_team != 'ExEd' &&
          selected_team != '' &&
          selected_team != 'SR'
        "
        class="no-padding"  >
          <v-select placeholder="Spocs" style="z-index: 1000; padding-top: 7%;" class="w-full" v-model="selected_spoc"
            :options="spocs" label="Spocss"></v-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.7" v-if="selected_team == 'SR'"
          style="margin-top: 1.5%" class="spoc-tree-drop none_padding" id="cc">

          <!-- <vs-dropdown vs-custom-content vs-trigger-click>
              <a class="a-icon" href.prevent @click="showTreeBox">
                Select SR Spoc
                <i class="material-icons">expand_more</i>
              </a>
              <vs-dropdown-menu class="customDropDown">
                
              </vs-dropdown-menu>
            </vs-dropdown> -->
          <!-- <div v-if="logged_in_team != 'SR'" style="margin-left: 8%">
            <treeselect :multiple="true" :options="spocs" :value-consists-of="'LEAF_PRIORITY'" value-format="object"
                  placeholder="Select SR Spoc" v-model="selected_sr_spoc" style="width:100%; position:relative; z-index: 5000;" />
          </div>
          <div v-else style="margin-left: 8%">
            <treeselect disabled :multiple="true" :options="spocs" :value-consists-of="'LEAF_PRIORITY'" value-format="object"
                  placeholder="Select SR Spoc" v-model="selected_sr_spoc" style="width:100%; position:relative; z-index: 5000;" />
          </div> -->
          <div v-if="logged_in_team != 'SR'">
            <vs-dropdown vs-custom-content vs-trigger-click class="seleced_spoc">
              <a class="a-icon select-iconns element-text-inside alignment " href.prevent @click="showTreeBox">
                Select SR SPOC
                <i class="material-icons">expand_more</i>
              </a>
              <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                <treeselect :multiple="true" :options="sr_spocs_ccf" :value-consists-of="'LEAF_PRIORITY'" value-format="object"
                  v-model="selected_sr_spoc" style="width:290px; position:relative; z-index: 5000;" />
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div v-else>
            <vs-dropdown vs-custom-content vs-trigger-click class="seleced_spoc">
              <a class="a-icon select-iconns element-text-inside alignment py-1" href.prevent @click="showTreeBox">
                Select SR SPOC
                <i class="material-icons">expand_more</i>
              </a>
              <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                <treeselect disabled :multiple="true" :options="sr_spocs_ccf" :value-consists-of="'LEAF_PRIORITY'" value-format="object"
                  v-model="selected_sr_spoc" style="width:290px; position:relative; z-index: 5000;" />
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2" style="margin-top: 1.5%">
          <vs-button color="dark" type="filled" @click="getCityData">GO</vs-button>
        </vs-col>
      </vs-row>
      <div class="admin_search_criteria mt-3 mb-5 ml-3" v-if="selected_team == 'SR'">
        <h6 class="Criteria_heading ml-2 mt-2">Search Criteria:</h6>
        <div style="display: inline-block; width: 100%; margin-left:10px; padding: 10px;">
          <span v-for="chip in searchCriteria" :key="chip.id">
            <vs-chip class="admin_criteria_chip" style="color:#000000; background: rgb(234, 229, 250)" closable @click="removechip(chip)">
              <b>{{ chip.label }}</b>
            </vs-chip>
          </span>
        </div>
      </div>
      <vx-card actionable class="cardx " v-if="loaded">
        <vs-row type="flex" style="margin-top: 2%">
          <vs-col vs-order="1" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" style="font-size: 19px">
            <b>Call Logs</b>
          </vs-col>
          <vs-col vs-order="1" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" style="font-size: 19px">
            <b>Work Logs</b>
          </vs-col>
        </vs-row>
        <div style="
                width: 100%;
                max-height: 80vh;
                height: auto;
                overflow: auto;
                margin-left: 10px;
              ">
          <table width="700px">
            <thead>
              <tr>
                <th style="min-width: 75px; width: 75px" class="col-id-no fixed-header">
                  SPOC Name
                </th>
                <th style="min-width: 100px; width: 100px" class="col-first-name fixed-header">
                  Reporting To
                </th>
                <th style="min-width: 100px; width: 100px">
                  Spoc Level
                </th>
                <th style="min-width: 100px; width: 100px">
                  City Tier
                </th>
                <th style="min-width: 100px; width: 100px">
                  Missed Calls
                </th>
                <th style="min-width: 100px; width: 100px">
                  Untracked
                </th>
                <th style="min-width: 100px; width: 100px">
                  To Update
                </th>
                <!-- <th style="min-width: 100px; width: 100px">
                  Call Logs Today
                </th> -->

                <th style="min-width: 100px; width: 100px" v-if="selected_team != 'ExEd'">
                  IVR All Calls
                </th>
                <th style="min-width: 100px; width: 100px" v-if="selected_team != 'ExEd'">
                  IVR Missed Calls
                </th>
                <th v-if="selected_team != 'SR'" style="min-width: 100px; width: 100px">
                  Untapped
                </th>
                <th v-if="selected_team == 'SR'" style="min-width: 100px; width: 100px">
                  Referrals
                </th>
                <th style="min-width: 100px; width: 100px">
                  Delays
                </th>
                <th v-if="selected_team != 'SR'" style="min-width: 100px; width: 100px">
                  NE Delays
                </th>
                <th v-if="selected_team != 'SR'" style="min-width: 100px; width: 100px">
                  Other Delays
                </th>
                <th style="min-width: 100px; width: 100px">
                  Today
                </th>
                <th style="min-width: 100px; width: 100px">
                  Waiting
                </th>
                <th style="min-width: 100px; width: 100px">
                  MHPS
                </th>
                <th style="min-width: 100px; width: 100px">
                  MHP Called
                </th>
                <th style="min-width: 100px; width: 100px">
                  MHP Not Called
                </th>
                <!-- <th v-if="selected_team != 'SR'" style="min-width: 100px; width: 100px">
                  Net Enquiries
                </th> -->
                <th v-if="selected_team != 'SR'" style="min-width: 100px; width: 100px">
                  NE Bucket 1
                </th>
                <th v-if="selected_team != 'SR'" style="min-width: 100px; width: 100px">
                  NE Bucket 2
                </th>
                <th style="min-width: 100px; width: 100px" v-if="selected_team != 'ExEd'">
                  Flag
                </th>
                <th style="min-width: 100px; width: 100px" >
                  Lead Count
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tr, index) in table_data" :key="index">
                <td class="col-id-no" scope="row">{{ tr.spoc_name }}</td>
                <td class="col-first-name" scope="row">{{ tr.reporting_manager }}</td>
                <td>{{ tr.user_tag }}</td>
                <td>{{ tr.city_classification }}</td>
                <td>
                  <a @click="getData(tr.user_id, 'missed_calls')">
                    {{ tr.missed_calls }}
                  </a>
                </td>
                <td><a @click="getData(tr.user_id, 'untracked')">
                    {{ tr.untracked_calls }}
                  </a></td>
                <td>
                  <a @click="getData(tr.user_id, 'to_update')">
                    {{ tr.to_update_calls }}
                  </a>
                </td>
                <!-- <td>
                  <a @click="getData(tr.user_id, 'call_logs_today')">
                    {{ tr.today_calls_logs }}
                  </a>
                </td> -->
                <td v-if="selected_team != 'ExEd'">
                  <a @click="getData(tr.user_id, 'ivr_all_calls')">
                    {{ tr.ivr_all_calls }}
                  </a>
                </td>
                <td v-if="selected_team != 'ExEd'">
                  <a @click="getData(tr.user_id, 'ivr_missed_calls')">
                    {{ tr.ivr_missed_calls }}
                  </a>
                </td>
                <td v-if="selected_team != 'SR'">
                  <a @click="getData(tr.user_id, 'untapped')">
                    {{ tr.untapped }}
                  </a>
                </td>
                <td v-if="selected_team == 'SR'">
                  <a @click="getData(tr.user_id, 'referral')">
                    {{ tr.referral }}
                  </a>
                </td>
                <td>
                  <a @click="getData(tr.user_id, 'delays')">
                    {{ tr.delays }}
                  </a>
                </td>
                <td v-if="selected_team != 'SR'">
                  <a @click="getData(tr.user_id, 'ne_delays')">
                    {{ tr.ne_delays }}
                  </a>
                </td>
                <td v-if="selected_team != 'SR'">
                  <a @click="getData(tr.user_id, 'other_delays')">
                    {{ tr.other_delays }}
                  </a>
                </td>
                <td>
                  <a @click="getData(tr.user_id, 'today')">
                    {{ tr.today }}
                  </a>
                </td>
                <td>
                  <a @click="getData(tr.user_id, 'waiting')">
                    {{ tr.waiting_calls }}
                  </a>
                </td>
                <td>
                  <a @click="getData(tr.user_id, 'mhps')">
                    {{ tr.mhp }}
                  </a>
                </td>
                <td>
                  <a @click="getData(tr.user_id, 'mhp_called')">
                    {{ tr.mhp_called }}
                  </a>
                </td>
                <td>
                  <a @click="getData(tr.user_id, 'mhp_not_called')">
                    {{ tr.mhp_not_called }}
                  </a>
                </td>
                <!-- <td v-if="selected_team != 'SR'">
                  <a @click="
                    getData(
                      tr.user_id,
                      'net_enquiries',
                      tr.net_enquiries
                    )
                  ">{{ tr.net_enquiries }}</a>
                </td> -->
                <td v-if="selected_team != 'SR'">
                  <a @click="
                    getData(
                      tr.user_id,
                      'net_website_google',
                      tr.net_website_google
                    )
                  ">{{ tr.net_website_google }}</a>
                </td>
                <td v-if="selected_team != 'SR'">
                  <a @click="
                    getData(
                      tr.user_id,
                      'net_other_sources',
                      tr.net_other_sources
                    )
                  ">{{ tr.net_other_sources }}</a>
                </td>
                <td v-if="selected_team != 'ExEd'">
                  <a @click="getData(tr.user_id, 'flag')">{{
                    tr.flag
                  }}</a>
                </td>
                <td >
                  <a @click="getData(tr.user_id, 'lead_count')">{{
                    tr.lead_count
                  }}</a>
                </td>
              </tr>
              <vs-tr v-show="hideSpocTotal">
                <vs-td class="col-id-no">
                  {{ spocs_total.Header }}
                </vs-td>
                <vs-td class="col-first-name"> </vs-td>
                <vs-td> </vs-td>
                <vs-td>
                  {{ spocs_total.missed_calls }}
                </vs-td>
                <vs-td>
                  {{ spocs_total.untracked }}
                </vs-td>
                <vs-td>
                  {{ spocs_total.to_update }}
                </vs-td>
                <!-- <vs-td>
                  {{ spocs_total.call_logs_today }}
                </vs-td> -->
                <vs-td v-if="selected_team != 'ExEd'">
                  {{ spocs_total.ivr_all_calls }}
                </vs-td>
                <vs-td v-if="selected_team != 'ExEd'">
                  {{ spocs_total.ivr_missed_calls }}
                </vs-td>
                <vs-td v-if="selected_team != 'SR'">
                  {{ spocs_total.untapped }}
                </vs-td>
                <vs-td v-if="selected_team == 'SR'">
                  {{ spocs_total.referral }}
                </vs-td>
                <vs-td>
                  {{ spocs_total.delays }}
                </vs-td>
                <vs-td v-if="selected_team != 'SR'">
                  {{ spocs_total.ne_delays }}
                </vs-td>
                <vs-td v-if="selected_team != 'SR'">
                  {{ spocs_total.other_delays }}
                </vs-td>
                <vs-td>
                  {{ spocs_total.today }}
                </vs-td>
                <vs-td>
                  {{ spocs_total.waiting }}
                </vs-td>
                <vs-td>
                  {{ spocs_total.mhps }}
                </vs-td>
                <vs-td>
                  {{ spocs_total.mhp_called }}
                </vs-td>
                <vs-td>
                  {{ spocs_total.mhp_not_called }}
                </vs-td>
                <!-- <vs-td v-if="selected_team != 'SR'">
                  {{ spocs_total.net_enquiries }}
                </vs-td> -->
                <vs-td v-if="selected_team != 'SR'">
                  {{ spocs_total.net_website_google }}
                </vs-td>
                <vs-td v-if="selected_team != 'SR'">
                  {{ spocs_total.net_other_sources }}
                </vs-td>
                <vs-td v-if="selected_team != 'ExEd'">
                  {{ spocs_total.flag }}
                </vs-td>
                <vs-td >
                  {{ spocs_total.lead_count }}
                </vs-td>
              </vs-tr>
              <vs-tr v-show="hidetotal">
                <vs-td class="col-id-no">
                  {{ total.Header }}
                </vs-td>
                <vs-td class="col-first-name"> </vs-td>
                <vs-td> </vs-td>
                <vs-td>
                  {{ total.missed_calls }}
                </vs-td>
                <vs-td>
                  {{ total.untracked }}
                </vs-td>
                <vs-td>
                  {{ total.to_update }}
                </vs-td>
                <!-- <vs-td>
                  {{ total.call_logs_today }}
                </vs-td> -->
                <vs-td v-if="selected_team != 'ExEd'">
                  {{ total.ivr_all_calls }}
                </vs-td>
                <vs-td v-if="selected_team != 'ExEd'">
                  {{ total.ivr_missed_calls }}
                </vs-td>
                <vs-td v-if="selected_team != 'SR'">
                  {{ total.untapped }}
                </vs-td>
                <vs-td v-if="selected_team == 'SR'">
                  {{ total.referral }}
                </vs-td>
                <vs-td>
                  {{ total.delays }}
                </vs-td>
                <vs-td v-if="selected_team != 'SR'">
                  {{ total.ne_delays }}
                </vs-td>
                <vs-td v-if="selected_team != 'SR'">
                  {{ total.other_delays }}
                </vs-td>
                <vs-td>
                  {{ total.today }}
                </vs-td>
                <vs-td>
                  {{ total.waiting }}
                </vs-td>
                <vs-td>
                  {{ total.mhps }}
                </vs-td>
                <vs-td>
                  {{ total.mhp_called }}
                </vs-td>
                <vs-td>
                  {{ total.mhp_not_called }}
                </vs-td>
                <!-- <vs-td v-if="selected_team != 'SR'">
                  {{ total.net_enquiries }}
                </vs-td> -->
                <vs-td v-if="selected_team != 'SR'">
                  {{ total.net_website_google }}
                </vs-td>
                <vs-td v-if="selected_team != 'SR'">
                  {{ total.net_other_sources }}
                </vs-td>
                <vs-td v-if="selected_team != 'ExEd'">
                  {{ total.flag }}
                </vs-td>
                <vs-td >
                  {{ total.lead_count }}
                </vs-td>
              </vs-tr>
            </tbody>
          </table>
        </div>
      </vx-card>
    </vx-card>
    <SpocDashboardCallLogPopup></SpocDashboardCallLogPopup>
    <SpocDashboardAdminToUpdate></SpocDashboardAdminToUpdate>
    <SpocDashboardAdminWorkLog></SpocDashboardAdminWorkLog>
    <SpocDashboardCmNetEnquiries></SpocDashboardCmNetEnquiries>
    <SpocDBAdminIVRallCalls></SpocDBAdminIVRallCalls>
  </div>
</template>

<script>
import vSelect from "vue-select";
import eventbus from "../components/eventbus";
import constants from "../../constants.json";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import axios from "axios";
import SpocDashboardCallLogPopup from "../components/pagesComponents/SpocDashboardAdminCallLog.vue";
import SpocDashboardAdminToUpdate from "../components/pagesComponents/SpocDashboardAdminToUpdate.vue";
import SpocDashboardAdminWorkLog from "../components/pagesComponents/SpocDashboardAdminWorkLog.vue";
import SpocDashboardCmNetEnquiries from "../components/pagesComponents/SpocDashboardCMNetenquiries.vue";
import SpocDBAdminIVRallCalls from "../components/pagesComponents/SpocDBAdminIVRallCalls.vue";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
import "vue-tree-halower/dist/halower-tree.min.css";
import { VTree, VSelectTree } from "vue-tree-halower";

export default {
  data() {
    return {
      spocs_ids:[],
      login_spoc_id: "",
      selected_sr_spoc: [],
      // spocs_tree: [
      //   {
      //     id: "All",
      //     label: "All",
      //     children: []
      //   }
      // ],
      hidetotal: false,
      hideSpocTotal: false,
      selected_spoc:"",
      dashboard_data: [],
      cities: [],
      selected_city: "",
      city_classification: [],
      selected_city_classification: "",
      table_data: [],
      loaded: false,
      total: {
        missed_calls: [],
        untracked: [],
        to_update: [],
        // call_logs_today: [],
        untapped: [],
        referral: [],
        delays: [],
        today: [],
        waiting: [],
        // net_enquiries: [],
        net_website_google: [],
        net_other_sources: [],
        mhps: [],
        mhp_called: [],
        mhp_not_called: [],
        flag: [],
      },
      spoc_with_teams: [],
      selected_team: "",
      teams: [],
      spocs: [],
      sr_spocs_ccf: [
        {
          id: "All",
          label: "All",
          children: [],
        }
      ],
      selected_course: "",
      courses: [],
      logged_in_team: "",
      searchCriteria: [],
      gm_citys: [],
      gm_spoc_ids: [],
      gm_reporting_spocs: '',
      admin_access_spocs : [
        "kabir.chawla@mileseducation.com",
        "ghafir.samad@mileseducation.com",
        "sharon.j@mileseducation.com",
        "athira.m@mileseducation.com",
        "nidhin.ram@mileseducation.com",
        "ghouse.ahmed@mileseducation.com",
        "punith.rao@mileseducation.com",
        "satyam.bhivgade@mileseducation.com",
        "krishna.kanth@mileseducation.com",
        "satram.chaitanya@mileseducation.com",
        "johnadhoc@example.com",
        "apoorva.agarwal@mileseducation.com",
        "pramod.sharma@mileseducation.com",
        "gagan.jot@mileseducation.com",
        "anjum.taj@mileseducation.com",
        "reshma.johnson@mileseducation.com",
        "simran.basha@mileseducation.com",
      ],
      spoc_email_id: '',
    };
  },
  components: {
    SpocDashboardCallLogPopup,
    SpocDashboardAdminToUpdate,
    SpocDashboardAdminWorkLog,
    SpocDashboardCmNetEnquiries,
    SpocDBAdminIVRallCalls,
    "v-select": vSelect,
    Treeselect,
    VSelectTree,
    VTree,
    VuePerfectScrollbar
  },
  mounted() {
    this.login_spoc_id = localStorage.getItem("spoc_id");
    this.logged_in_team = localStorage.getItem('team');
    this.getTeams();
    // this.getDashboardData();
    this.spoc_email_id = localStorage.getItem("email_id");
    if (this.admin_access_spocs.includes(this.spoc_email_id)) {
      this.logged_in_team = 'Admin';
    }
    if(this.logged_in_team == 'GM' && localStorage.getItem("sub_team") == 'CM'){
      this.getGmReportingSpocs()
    }
  },
  watch: {
    selected_sr_spoc: function () {
      this.mergeAllSearch();
    },
    selected_team: function (val) {
      this.table_data = [];
      this.spocs = [];
      this.sr_spocs_ccf = [
        {
          id: "All",
          label: "All",
          children: [],
        }
      ],
      this.courses = [];
      this.hidetotal = false;
      this.hideSpocTotal = false;
      this.clearTotal()
      this.selected_spoc = "";
      if (val == "CM" && this.logged_in_team != 'GM' && localStorage.getItem("sub_team") != 'CM') {
        // this.cities = [
        //   ...new Set(this.spoc_with_teams.map((item) => item.city)),
        // ];
        // Sorting cities alphabetically
        // this.cities.sort((a, b) => {
        //   if (a < b) return -1;
        //   return a > b ? 1 : 0;
        // });
        let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
        this.cities = [];
        let citys = [
          ...new Set(this.spoc_with_teams.map((item) => item.city)),
        ];
        citys.forEach(element => {
        if(city_options.includes(element)){
          this.cities.push(element);
        }
        })
      }
      else if (val == "CM" && this.logged_in_team == 'GM' && localStorage.getItem("sub_team") == 'CM') {
        this.cities = this.gm_citys;
        console.log("this.gm_citys", this.gm_citys);
      }
      else if (val == "CM" && this.logged_in_team == 'Admin') {
        // this.cities = [
        //   ...new Set(this.spoc_with_teams.map((item) => item.city)),
        // ];
        let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
        this.cities = [];
        let citys = [
          ...new Set(this.spoc_with_teams.map((item) => item.city)),
        ];
        citys.forEach(element => {
        if(city_options.includes(element)){
          this.cities.push(element);
        }
        })
      }
      else if (val == "ExEd") {
        this.courses = [
          ...new Set(
            this.spoc_with_teams.map((item) =>
              item.team == "ExEd" ? item.sub_team : ""
            )
          ),
        ];
        console.log("courses", this.courses);
      }
      else if (val == "SR") {
        // let obj = {
        //   id: "All",
        //   label: "All",
        //   children: [],
        // }
        // this.spocs.push(obj);
        // console.log("pushed tree obj", this.spocs);
        let sr_spocs = [];
        for (let i = 0; i < this.spoc_with_teams.length; i++) {
          const element = this.spoc_with_teams[i];
          if (element.team == 'SR' && element.sub_team == 'SR') {
            sr_spocs.push(element)
          }
        }
        console.log("sr_spocs", sr_spocs);
        var unique = sr_spocs
          .map((name) => {
            return {
              count: 1,
              name: name.city,
            };
          })
          .reduce((a, b) => {
            a[b.name] = (a[b.name] || 0) + b.count;
            return a;
          }, {});
        for (var key in unique) {
          if (unique.hasOwnProperty(key)) {
            var city_obj = {
              id: key,
              label: key,
              children: [],
            };
            this.sr_spocs_ccf[0].children.push(city_obj);
          }
        }
        sr_spocs.forEach((server_spoc) => {
          this.sr_spocs_ccf.forEach((bde) => {

            bde.children.forEach((bdechid) => {

              if (bdechid.label === server_spoc.city) {

                let name_obj = {
                  id: server_spoc.first_name + " " + server_spoc.last_name,
                  label: server_spoc.first_name + " " + server_spoc.last_name,
                };
                bdechid.children.push(name_obj);
                // console.log("unique", bdechid)
              }
            });
          });
        });

        console.log("spocs array", this.sr_spocs_ccf);
        // console.log("SR spocs with city classification",this.spoc_with_teams);
        // for (let i = 0; i < this.spoc_with_teams.length; i++) {
        //   const element = this.spoc_with_teams[i];
        //   if(element.sub_team == "SR" && element.team == "SR"){
        //     console.log("SR spocs with city classification",element);
        //   }
        // }
        // this.spocs = [
        //   ...new Set(
        //     this.spoc_with_teams.map((item) =>
        //       item.team == val && item.sub_team == "SR"
        //         ? item.first_name + " " + item.last_name
        //         : ""
        //     )
        //   ),
        // ];
        // console.log("sr spocs", this.spocs);
      } else if (val == "GM") {
        this.spocs = [
          ...new Set(
            this.spoc_with_teams.map((item) =>
              item.team == val ? item.first_name + " " + item.last_name : ""
            )
          ),
        ];
        console.log("gm spocs", this.spocs);
      }
      // else if (val == "CR") {
      //   this.spocs = [
      //     ...new Set(
      //       this.spoc_with_teams.map((item) =>
      //         item.team == "SR" && item.sub_team == "Corporate Relations"
      //           ? item.first_name + " " + item.last_name
      //           : ""
      //       )
      //     ),
      //   ];
      //   console.log("cr spocs", this.spocs);
      // } 
      // else if (val == "Collections") {
      //   this.spocs = [
      //     ...new Set(
      //       this.spoc_with_teams.map((item) =>
      //         item.team == "SR" && item.sub_team == "Collection"
      //           ? item.first_name + " " + item.last_name
      //           : ""
      //       )
      //     ),
      //   ];
      //   console.log("collections spocs", this.spocs);
      // } 
      else if (val == "Placements") {
        console.log(val, "team");
        this.spocs = [
          ...new Set(
            this.spoc_with_teams.map((item) =>
              item.team == "SR" && item.sub_team == "Alumni SR"
                ? item.first_name + " " + item.last_name
                : ""
            )
          ),
        ];
        console.log("placements spocs", this.spocs);
      }

      // this.spocs.sort(function (a, b) {
      //   if (a > b) return 1;
      //   if (a < b) return -1;
      // });
      if (val != "SR") {
        this.spocs.splice("", 1);
        this.spocs.unshift("All");
      }
      this.courses.splice("", 1);
      // this.courses.unshift('All')
      let trah_cities = ['IIML-SF','IIML-FT','Trash','Y-city']
      trah_cities.forEach(city => {
        if (this.cities.indexOf(city)>-1) {
          this.cities.splice(this.cities.indexOf(city),1)
        }
      });
      // this.getDashboardData(val);
    },
    selected_city: function (val) {
      this.city_classification = [];
      this.selected_city_classification = "";
      let classification = ["All"];
      // if(this.logged_in_team == 'GM' && localStorage.getItem("sub_team") == 'CM'){
      //     console.log("gm_reporting_spocs", this.gm_reporting_spocs);
      //     for (let i = 0; i < this.gm_reporting_spocs.length; i++) {
      //     if (
      //       val == this.gm_reporting_spocs[i].city &&
      //       this.gm_reporting_spocs[i].active
      //     ) {
      //       classification.push(this.gm_reporting_spocs[i].city_classification);
      //     }
      //     }
      // }else{
      for (let i = 0; i < this.spoc_with_teams.length; i++) {
        if (
          val == this.spoc_with_teams[i].city &&
          this.spoc_with_teams[i].team == "CM"
        ) {
          classification.push(this.spoc_with_teams[i].city_classification);
        }
      // }
      }

      console.log("classification", classification);
      // To get distinct values from an array
      this.city_classification = [
        ...new Set(classification.map((item) => item)),
      ];
      console.log(this.city_classification,"city_classification")
      if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
             this.city_classification
            }
            else{
              this.city_classification = this.city_classification.filter(item => item !== 'X');
   
           }

    },
    selected_city_classification(){
      this.clearTotal();
      this.getSpocsData()
    },
    selected_course(){
      this.getSpocsData()
    },
    selected_spoc(){
      this.getSpocsData()
    }
  },
  methods: {
    getTeam(providedTeam) {
      if (localStorage.getItem("team") === providedTeam) {
        return true;
      } else {
        return false;
      }
    },
    getSpocsData() {
      let spoc_ids = []
      if (this.selected_team == "CM" && this.logged_in_team != 'GM' && localStorage.getItem("sub_team") != 'CM') {
        if (this.selected_city_classification != "") {
          if (this.selected_city_classification == "All") {
            spoc_ids = [
              ...new Set(
                this.spoc_with_teams.map((item) =>
                  item.team == "CM" &&
                  item.city == this.selected_city ? item.id : ""
                )
              ),
            ];
          } else {
            spoc_ids = [
              ...new Set(
                this.spoc_with_teams.map((item) =>
                  item.team == "CM" &&
                  item.city == this.selected_city &&
                  item.city_classification == this.selected_city_classification
                    ? item.id
                    : ""
                )
              ),
            ];
          }
        }
      }else if (this.selected_team == "CM" && this.logged_in_team == 'GM' && localStorage.getItem("sub_team") == 'CM') {
        let repoting_to = localStorage.getItem('spoc_id');
        if (this.selected_city_classification != "") {
          if (this.selected_city_classification === "All") {
            spoc_ids = [...new Set(this.spoc_with_teams.map((item) => item.team == 'CM' && repoting_to == item.reporting_to && item.city == this.selected_city ? item.id : "")),];
          } else {
            spoc_ids = [...new Set(this.spoc_with_teams.map((item) => item.team == 'CM' && this.selected_city == item.city && this.selected_city_classification == item.city_classification && repoting_to == item.reporting_to ? item.id : "")),];
          }
        }
      }  else if (this.selected_team == "SR") {
        for (let i = 0; i < this.spoc_with_teams.length; i++) {
          const spoc = this.spoc_with_teams[i];
          for (let j = 0; j < this.selected_sr_spoc.length; j++) {
            const sr_spocs = this.selected_sr_spoc[j].id;
            if (sr_spocs == spoc.full_name && spoc.sub_team == "SR" && spoc.team == "SR") {
              spoc_ids.push(spoc.id)
            }
          }
        }
        } else if (this.selected_team == "GM") {
        if (this.selected_spoc === "All") {
         spoc_ids = [
              ...new Set(
                this.spoc_with_teams.map((item) =>
                  item.team == "GM" && item.sub_team == 'GM' ? item.id : ""
                )
              ),]
        } else {
         spoc_ids = [
              ...new Set(
                this.spoc_with_teams.map((item) =>
                  item.team == "GM" && this.selected_spoc == item.first_name + ' ' + item.last_name ? item.id : ""
                )
              )]
        }
        
        } else if (this.selected_team == "Placements") {
        if (this.selected_spoc === "All") {
         spoc_ids = [
              ...new Set(
                this.spoc_with_teams.map((item) =>
                  item.team == "SR" && item.sub_team == 'Alumni SR' ? item.id : ""
                )
              ),]
        } else {
         spoc_ids = [
              ...new Set(
                this.spoc_with_teams.map((item) =>
                  item.team == "SR" && item.sub_team == 'Alumni SR' && this.selected_spoc == item.first_name + ' ' + item.last_name ? item.id : ""
                )
              )]
        }
        } else if (this.selected_team == "ExEd") {
          if (this.selected_course != "") {
            for (let i = 0; i < this.spoc_with_teams.length; i++) {
              if (this.selected_course == this.spoc_with_teams[i].sub_team) {
                spoc_ids.push(this.spoc_with_teams[i].id);
              }
            }
          }
      }
      this.spocs_ids = []
      this.spocs_ids = spoc_ids
    },
    getGmReportingSpocs() {
      let gm_id = localStorage.getItem("spoc_id");
      let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getGmReportingSpocs", response);
          if (response.data.spocs.length == 0) {
            this.logged_in_team = 'Admin';
            return false
          } else {
            this.gm_reporting_spocs = response.data.spocs
            let duplicates = []
            response.data.spocs.forEach(element => {
              if (!duplicates.includes(element.city) && element.active) {
                this.gm_citys.push(element.city)
                duplicates.push(element.city)
              }
            })
            return true
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    // showTreeBox(){
    //   this.spocs_tree;
    // },
    mergeAllSearch() {
      this.searchCriteria = [];
      this.selected_sr_spoc.forEach((sort) => {
        if (sort === "All") {
          this.sr_spocs_ccf[0].children.forEach((child) => {
            // this.searchCriteria.push(child.label);
            this.searchCriteria.push(child.label);
          });
        } else {
          // this.searchCriteria.push(sort);
          this.searchCriteria.push(sort);
        }
      });
      this.getSpocsData()
    },
    removechip(chip){
        if (this.selected_sr_spoc.indexOf(chip) === -1) {
          this.selected_sr_spoc = [];
          this.sr_spocs_ccf[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.selected_sr_spoc.push(child.label);
              this.searchCriteria.push(child.label);
            }
          });
        } else {
          this.selected_sr_spoc.splice(this.selected_sr_spoc.indexOf(chip), 1);
          this.searchCriteria.splice(this.searchCriteria.indexOf(chip), 1);
        }
    },
    getTeams() {
      let url = `${constants.SERVER_API}getAllActiveSpocsForDashbaord`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getAllSpocWithTeams", response);
          this.spoc_with_teams = response.data;
          this.teams = [
            ...new Set(this.spoc_with_teams.map((item) => item.team)),
          ];
          this.teams.push("Placements");
          console.log("logged in team, subteam", localStorage.getItem('team'), localStorage.getItem('sub_team'));
          if (this.logged_in_team == 'SR' && localStorage.getItem('sub_team') == 'SR') {
            this.selected_team = 'SR';
            this.teams = ['SR']
            let obj = {
              id : localStorage.getItem('spoc_name'),
              label : localStorage.getItem('spoc_name')
            }
            this.selected_sr_spoc.push(obj);
            this.getSpocsData();
          }
          else if(this.logged_in_team == 'GM' && localStorage.getItem("sub_team") == 'CM'){
            this.selected_team = 'CM';
            this.teams = ['CM']
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    // getDashboardData(team) {
    //   console.log("team .....", team)
    //   let url = "";
    //   if (team == "CM") {
    //     url = `${constants.MILES_CM_BACK}getPresignedUrlForSpocDashboardData?file_name=adminSpocDashboardCm.json`;
    //   } else if (team == "ExEd") {
    //     url = `${constants.MILES_CM_BACK}getPresignedUrlForSpocDashboardData?file_name=adminSpocDashboardExed.json`;
    //   } else if (
    //     team == "Placements" ||
    //     team == "SR" ||
    //     team == "GM" ||
    //     team == "CR" ||
    //     team == "Collections"
    //   ) {
    //     url = `${constants.MILES_CM_BACK}getPresignedUrlForSpocDashboardData?file_name=adminSpocDashboardSr.json`;
    //   }
    //   axios
    //     .get(url, {
    //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
    //     })
    //     .then((response) => {
    //       console.log("dashboard_data", response);
    //       this.dashboard_data = response.data;
    //       if (this.logged_in_team == 'SR' && localStorage.getItem('sub_team') == 'SR') {
    //         let obj = {
    //           id: localStorage.getItem('spoc_name'),
    //           label: localStorage.getItem('spoc_name')
    //         }
    //         this.selected_sr_spoc.push(obj);
    //         this.getSpocsData();
    //         this.getCityData();
    //       }
    //     })
    //     .catch((error) => {
    //       console.log("error", error);
    //     });
    // },
    async getCityData() {
      let table_data = [];
      let spocs_total_data = [];
      if (this.spocs_ids.length != 0) {
      this.$vs.loading();
        for (let i = 0; i < this.spocs_ids.length; i++) {
          console.log(this.spocs_ids.length, i);
          const spoc = this.spocs_ids[i];
          if (spoc != "") {
            let response = await this.getSpocDashboardForAdminData(spoc);
            if (response != null) {
              table_data.push(response);
              console.log("dataaaa", response);
              if(response.user_tag != "Dummy" && response.user_tag != "X"){
                spocs_total_data.push(response);
                console.log("spocs_total_data", response);
              }
            }
          }
        }
      }else{
        this.$vs.notify({
          title: "No data found",
          color: "danger",
        });
      }
      this.totalCalculate(table_data);
      this.calculateSpocsTotal(spocs_total_data);
    },
    getSpocDashboardForAdminData(spoc) {
      let url = `${constants.SERVER_API}getSpocDashboardForAdminData?spoc_id=${spoc}&team=${this.selected_team}`;
      const promise = new Promise((resolve) => {
        axios
          .get(url, {headers: {Authorization: `Bearer ${localStorage.userAccessToken}`}})
          .then((response) => {
            resolve(response.data[0]);
          })
          .catch((error) => {
            console.log(error);
            this.handleError(error);
            resolve(null);
          });
      });
      return promise;
    },
    totalCalculate(Spocs) {
      this.$vs.loading.close();
      if (Spocs.length != 0) {
      console.log('Spocs',Spocs);
      this.total.Header = "TOTAL";
      this.total.empty = "";
      this.total.missed_calls = Spocs.reduce((accumulator, object) => {return accumulator + object.missed_calls}, 0);
      this.total.untracked = Spocs.reduce(
        (n, { untracked_calls }) => n + untracked_calls,
        0
      );
      this.total.to_update = Spocs.reduce(
        (n, { to_update_calls }) => n + to_update_calls,
        0
      );
      // this.total.call_logs_today = Spocs.reduce((n, { today_calls_logs }) => n + today_calls_logs,0);
      this.total.untapped = Spocs.reduce((n, { untapped }) => n + untapped, 0);
      this.total.referral = Spocs.reduce((n, { referral }) => n + referral, 0);
      this.total.delays = Spocs.reduce((n, { delays }) => n + delays, 0);
      this.total.today = Spocs.reduce((n, { today }) => n + today, 0);
      this.total.waiting = Spocs.reduce(
        (n, { waiting_calls }) => n + waiting_calls,
        0
      );
      this.total.mhps = Spocs.reduce((n, { mhp }) => n + mhp, 0);
      this.total.mhp_called = Spocs.reduce((n, { mhp_called }) => n + mhp_called, 0);
      this.total.mhp_not_called = Spocs.reduce((n, { mhp_not_called }) => n + mhp_not_called, 0);
      this.total.net_website_google = Spocs.reduce(
        (n, { net_website_google }) => n + net_website_google,
        0
      );
       this.total.net_other_sources = Spocs.reduce(
        (n, { net_other_sources }) => n + net_other_sources,
        0
      );
      this.total.flag = Spocs.reduce((n, { flag }) => n + flag, 0);
      this.total.lead_count = Spocs.reduce((n, { lead_count }) => n + lead_count, 0);
      this.total['ivr_all_calls'] = Spocs.reduce((n, { ivr_all_calls }) => n + ivr_all_calls, 0);
      this.total['ivr_missed_calls'] = Spocs.reduce((n, { ivr_missed_calls }) => n + ivr_missed_calls, 0);
      this.total['ne_delays'] = Spocs.reduce((n, { ne_delays }) => n + ne_delays, 0);
      this.total.other_delays = Spocs.reduce((n, { other_delays }) => n + other_delays, 0);
      console.log("total", this.total);
      this.hidetotal = true;
      this.loaded = true;
    }
    this.table_data = Spocs
    },

    calculateSpocsTotal(spocs_data){
      if (spocs_data.length != 0) {
      console.log('spocs_data',spocs_data);
      this.spocs_total.Header = "SPOC's TOTAL";
      this.spocs_total.empty = "";
      this.spocs_total.missed_calls = spocs_data.reduce((accumulator, object) => {return accumulator + object.missed_calls}, 0);
      this.spocs_total.untracked = spocs_data.reduce(
        (n, { untracked_calls }) => n + untracked_calls,
        0
      );
      this.spocs_total.to_update = spocs_data.reduce(
        (n, { to_update_calls }) => n + to_update_calls,
        0
      );
      // this.spocs_total.call_logs_today = spocs_data.reduce((n, { today_calls_logs }) => n + today_calls_logs,0);
      this.spocs_total.untapped = spocs_data.reduce((n, { untapped }) => n + untapped, 0);
      this.spocs_total.referral = spocs_data.reduce((n, { referral }) => n + referral, 0);
      this.spocs_total.delays = spocs_data.reduce((n, { delays }) => n + delays, 0);
      this.spocs_total.today = spocs_data.reduce((n, { today }) => n + today, 0);
      this.spocs_total.waiting = spocs_data.reduce(
        (n, { waiting_calls }) => n + waiting_calls,
        0
      );
      this.spocs_total.mhps = spocs_data.reduce((n, { mhp }) => n + mhp, 0);
      this.spocs_total.mhp_called = spocs_data.reduce((n, { mhp_called }) => n + mhp_called, 0);
      this.spocs_total.mhp_not_called = spocs_data.reduce((n, { mhp_not_called }) => n + mhp_not_called, 0);
      this.spocs_total.net_website_google = spocs_data.reduce(
        (n, { net_website_google }) => n + net_website_google,
        0
      );
       this.spocs_total.net_other_sources = spocs_data.reduce(
        (n, { net_other_sources }) => n + net_other_sources,
        0
      );
      this.spocs_total.flag = spocs_data.reduce((n, { flag }) => n + flag, 0);
      this.spocs_total.lead_count = spocs_data.reduce((n, { lead_count }) => n + lead_count, 0);
      this.spocs_total['ivr_all_calls'] = spocs_data.reduce((n, { ivr_all_calls }) => n + ivr_all_calls, 0);
      this.spocs_total['ivr_missed_calls'] = spocs_data.reduce((n, { ivr_missed_calls }) => n + ivr_missed_calls, 0);
      this.spocs_total['ne_delays'] = spocs_data.reduce((n, { ne_delays }) => n + ne_delays, 0);
      this.spocs_total.other_delays = spocs_data.reduce((n, { other_delays }) => n + other_delays, 0);
      console.log("spocs_total", this.spocs_total);
      if(this.selected_city_classification == "All"){
        this.hideSpocTotal = true;
      }else{
        this.hideSpocTotal = false;
      }
      this.loaded = true;
    }
    },
    getData(spoc_id, category) {
      this.$vs.loading()
      console.log("table data in get data", this.table_data);
      if (spoc_id != null && category != "") {
        let spocName = null;
        this.table_data.forEach((spoc) => {
          if (spoc.user_id === spoc_id) {
            spocName = spoc.spoc_name;
          }
        });
        let url = "";
        if (this.selected_team == "CM") {
          url = `${constants.ADMIN_SERVER_API}getSpocDashboardForAdminDetails`;
        } else if (this.selected_team == "ExEd") {
          url = `${constants.ADMIN_SERVER_API}getIIMLSpocDashboardForAdminDetails`;
        } else {
          url = `${constants.ADMIN_SERVER_API}getSRDashboardForAdminDetails`;
        }
        let obj = {};
        if (category != "net_website_google" && category != "net_other_sources") {
          obj = {
            spoc_id: spoc_id,
            category: category,
          };
        }
        if (category == "net_website_google" || category == "net_other_sources") {
          obj = {
            spoc_id: spoc_id,
            category: category,
            selected_city: this.selected_city
          };
        }
        axios
          .get(url, {
            params: obj,
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            this.response = response;
            if (category === "net_website_google" || category === "net_other_sources") {
              eventbus.$emit(
                "spocDashboardAdminCM-NetEnquiries",
                // NetEnquiriesData,
                // spocName
                response.data,
                url,
                obj,
                category,
                spocName
              );
            }
            if (category === "missed_calls" || category === "untracked") {
            // if (category === "missed_calls" || category === "untracked" || category === "call_logs_today") {
              console.log("checking response", response);
              eventbus.$emit(
                "spocDashboardAdmin-calllog",
                response.data,
                url,
                obj,
                category,
                spocName
              );
            } else if (category === "to_update") {
              eventbus.$emit(
                "spocDashboardAdmin-toUpdate",
                response.data,
                url,
                obj,
                category,
                spocName
              );
            } else if (
              category === "referral" ||
              category === "delays" ||
              category === "ne_delays" ||
              category === "other_delays" ||
              category === "today" ||
              category === "waiting" ||
              category === "mhps" ||
              category === "mhp_called" ||
              category === "mhp_not_called" ||
              category === "untapped" ||
              category === "flag" ||
              category === "lead_count"
            ) {
              this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
              eventbus.$emit("spocDashboardAdmin-WorkLog", [
                response,
                url,
                obj,
                spocName,
              ]);
            } else if (category === "ivr_all_calls" || category === "ivr_missed_calls") {
              eventbus.$emit(
                "spocDashboardAdmin-IVRcalls",
                response.data.data,
                url,
                obj,
                category,
                spocName
              );
            }
            // else if (category === "net_enquiries") {
            //   this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
            //   eventbus.$emit("spocDashboardAdminCM-NetEnquiries", [
            //     response,
            //     url,
            //     obj,
            //   ]);
            // }
           this.$vs.loading.close()
          })
          .catch((error) => {
            this.handleError(error);
            this.$vs.loading.close()
          });
      } else {
        this.$vs.notify({
          title: "Error",
          color: "danger",
        });
      }
    },
    showTreeBox() {
      return this.sr_spocs_ccf;
    },
    clearTotal(){
      this.total = {
        missed_calls: 0,
        untracked: 0,
        to_update: 0,
        // call_logs_today: 0,
        untapped: 0,
        referral: 0,
        delays: 0,
        today: 0,
        waiting: 0,
        // net_enquiries: [],
        net_website_google: 0,
        net_other_sources: 0,
        mhps: 0,
        mhp_called: 0,
        mhp_not_called: 0,
        flag: 0,
        lead_count:0
      };
      this.spocs_total = {
        missed_calls: 0,
        untracked: 0,
        to_update: 0,
        // call_logs_today: 0,
        untapped: 0,
        referral: 0,
        delays: 0,
        today: 0,
        waiting: 0,
        // net_enquiries: [],
        net_website_google: 0,
        net_other_sources: 0,
        mhps: 0,
        mhp_called: 0,
        mhp_not_called: 0,
        flag: 0,
        lead_count:0
      };
    }
  },
};
</script>

<style scoped>
/* // @import "@/assets/scss/vuesax/pages/dropdown.scss"; */

.selectExample {
  width: 90%;
}

.vs-dropdown--menu {
  width: 300px;
}

thead th {
  top: 0;
  position: sticky;
  z-index: 20;
  background-color: white;
  color: black;
}
td.td-check {
    display: none !important;
}
.col-id-no {
  left: 0;
  position: sticky;
  background-color: white;
}

.col-first-name {
  left: 80px;
  position: sticky;
  background-color: white;
}

.fixed-header {
  z-index: 50;
}

table th,
td {
  outline: 1px solid #ccc;
}

.spoc-tree-drop>.vs-con-dropdown {
  /* width: 100%; */
  /* min-height: 32px; */
  border-radius: 10px;
  padding-inline: 5px;
  color: #000000;
  display: flex !important;
  align-items: center;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

i {
  font-size: 18px;
}

.element-text-inside {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding-inline-start: 10px;
}

.element-text-inside>i {
  color: rgba(0, 0, 0, 0.4);
}

.select-iconns>.material-icons {
  margin-left: auto;
}

.select-iconns {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  /* margin-top: 3px; */
  border: 1px solid lightgray;
  /* width: 168%; */
  height: 39px;
  border-radius: 5px;
  display: flex;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.admin_search_criteria {
  width: 99%;
  background: #ffffff;
  min-height: 165px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.Criteria_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.admin_criteria_chip {
  border-radius: 5px;
}

.admin_criteria_chip .vs-chip--close {
  background: transparent !important;
  color: #000000;
}
.seleced_spoc {
    width: 15vw;
  }
.no-padding{
  padding:0px;
}
.total_row > td.td-check {
    display: none !important;
}
.admin_criteria_chip .vs-chip--text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #000000;
}</style>